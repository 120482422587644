.App {
  height: 100vh;
}

.bg {
  background: #080808;
}

.z-btn-1 {
  width: 100%;

  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  color: #333;
  padding: 15px 25px;

  border: none;
  transition: all 0.3s;
  
  &:hover {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  }
}

.chat-bubbles-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  position: absolute;
  top: 0;
  left: 0;

  padding-bottom: 13vh;
}

.chat-bubbles {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: stretch;
}

.double-container {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.flexbox-2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  padding-bottom: 20px;
}

.nav-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}


// Scrollbar Stuff
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #0963ca;
}

// Conversation Stuff
.conversations {
  height: 100%;
  width: 100%;

  padding: 10px 15px;
  border-radius: 20px;

  background: #3a3a3a;
}

.conv-container {
  padding: 3px 0;
}

.conv {
  color: white;
  cursor: pointer;
  transition: 0.3s;

  flex-grow: 2;

  padding: 9px 17px;
  margin-top: 6px;
  border-radius: 999px;

  &:hover {
    background: #0c538d;
  }
  
  &.selected {
    background: #2294fb;
  }
}

.conv-trash-container {
  padding: 9px 17px;
  margin-top: 6px;
  flex-basis: auto;
  
  .conv-trash {
    cursor: pointer;
    transition: 0.3s;
    font-size: 120%;
  
    &:hover {
      color: #f74f4f;
    }
  }
}

.new-conv-button {
  $main: #aaaaaa;

  color: $main;
  cursor: pointer;
  transition: 0.3s;

  padding: 9px 17px;
  margin-top: 8px;
  border-radius: 999px;

  border: 1px solid $main;

  &:hover {
    background: #363636;
  }
}
