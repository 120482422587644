.autogrow-field {
  outline: none;
  

  color: white;

  width: 50vw;

  border: 1px solid #234766;
  background-color: #383838;

  padding: 10px 15px;
  border-radius: 16px;

  z-index: 10;

  transition: 0.35s;

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    border-color: #1b70bb;
    background-color: #3f3f3f;
  }

  &:focus {
    border-color: #2294fb!important;
    background-color: #4d4d4d!important;
  }
}