.authbox {
  width: 100%;
  border-radius: 20px;

  background: #3a3a3a;
  padding: 12px 15px;

  &.logged-in {
    color: white;
  }

  .auth-input {
    padding: 4px 7px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    caret-color: white;
    color: white;

    outline: none!important;

    width: 100%;
    margin: 5px 0;

    border: none;
    border-bottom: 2px solid #a0a0a0;

    background: transparent;
    transition: 0.4s;

    &::placeholder {
      color: #a0a0a0;
    }

    &:hover:not(:focus) {
      background: rgba(156, 156, 156, 0.1);
      border-color: #b4b4b4;
    }

    &:focus {
      border-color: #2294fb;
      background: rgba(156, 156, 156, 0.18);
    }
  }

  .login-btn {
    background: #2294fb;
    color: white;
    padding: 7px 15px;

    text-align: center;
    border-radius: 999px;

    cursor: pointer;

    transition: 0.3s;

    &:hover {
      background: #1d83dd;
    }

    &.disabled {
      pointer-events: none;

      background: #7a7a7a;
    }
  }
}