.test-card {
  background: white;
  border-radius: 16px;
  max-width: 60%;
  padding: 13px;
  text-align: left;

  margin: 5px 6%;

  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);

  p:last-of-type {
    margin: 0;
  }

  &.from-user {
    align-self: flex-end;
    background: #2294fb;
    border: 1px solid #145996;
    color: white;
  }

  &.from-bot {
    align-self: flex-start;
    background: #4e4e4e;
    border: 1px solid #424242;
    color: white;
  }
}